import React from "react"
import { StaticQuery, graphql } from "gatsby"

import BlogPageComponent from "../page-components/BlogPageComponent"

const imageSrcFormatter = (imageSrc, type = "fixed") =>
  (imageSrc &&
    imageSrc.childImageSharp &&
    imageSrc.childImageSharp[type] &&
    imageSrc.childImageSharp[type].src) ||
  ""

const BlogPage = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          page: markdownRemark(fields: { slug: { eq: "/pages/blog/" } }) {
            frontmatter {
              title
              seoTitle
              seoDescription
              seoImage {
                childImageSharp {
                  fixed(width: 300, quality: 92) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              headerImage {
                childImageSharp {
                  fixed(width: 1600, quality: 92) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          posts: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
            sort: { fields: frontmatter___date, order: DESC }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  subtitle
                  text
                  date
                  author {
                    frontmatter {
                      author_id
                      name
                      title
                      profilePicture {
                        childImageSharp {
                          fluid(maxWidth: 100, quality: 92) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                  category
                  image {
                    childImageSharp {
                      fluid(maxWidth: 250, quality: 92) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
    >
      {data => {
        const {
          posts,
          page: {
            frontmatter: {
              title,
              seoTitle,
              seoDescription,
              seoImage,
              headerImage,
            },
          },
        } = data
        return (
          <BlogPageComponent
            title={title}
            seoTitle={seoTitle}
            seoDescription={seoDescription}
            seoImage={imageSrcFormatter(seoImage)}
            headerImage={imageSrcFormatter(headerImage)}
            blogPosts={posts}
            {...props}
          />
        )
      }}
    </StaticQuery>
  )
}

export default BlogPage
