import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import moment from "moment"
import "moment/locale/pt"

import styles from "./styles.module.css"

const BlogCard = ({
  slug,
  image,
  title,
  subtitle,
  author,
  publicationDate,
  readingTime,
}) => {
  return (
    <Link className={styles.blogCard} to={slug}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${
            image && image.childImageSharp && image.childImageSharp.fluid.src
          })`,
        }}
      >
        <div className={styles.overlay}></div>
        <h2 className={styles.title}>{title}</h2>
        <h3 className={styles.subtitle}>{subtitle}</h3>
      </div>
      <div className={styles.info}>
        <div
          className={styles.profilePicture}
          style={{
            backgroundImage: `url(${
              author.profilePicture &&
              author.profilePicture.childImageSharp.fluid.src
            })`,
          }}
        />
        <div className={styles.rightSection}>
          <span className={styles.authorName}>
            <strong>{author.name}</strong>
            <span className={styles.separator} />
            {author.title}
          </span>
          <span className={styles.date}>
            <strong>
              {moment(publicationDate).locale("pt").format("D MMMM YYYY")}
            </strong>
            {readingTime}
          </span>
        </div>
      </div>
    </Link>
  )
}

BlogCard.propTypes = {
  slug: PropTypes.string,
  image: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  author: PropTypes.object,
  publicationDate: PropTypes.any,
  readingTime: PropTypes.string,
}

export default BlogCard
