import React, { useState } from "react"

import SimpleLayout from "../layouts/SimpleLayout"
import BlogCard from "../components/BlogCard/BlogCard"
import Newsletter from "../components/Forms/Newsletter"
import SEO from "../components/seo"

import styles from "./styles/blog.module.css"

const normalizeString = string =>
  string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")

const BlogPageComponent = ({
  seoTitle,
  seoDescription,
  seoImage,
  title,
  blogPosts,
  headerImage,
  isPreview,
  ...props
}) => {
  const [filter, setFilter] = useState("")
  const [searchTerm, setSearchTerm] = useState()

  const posts = blogPosts => {
    const filtered = blogPosts.edges
      .filter(({ node }) =>
        filter ? node.frontmatter.category === filter : true
      )
      .filter(({ node }) =>
        searchTerm
          ? normalizeString(node.frontmatter.title).indexOf(
              normalizeString(searchTerm)
            ) >= 0 ||
            normalizeString(node.frontmatter.subtitle || "").indexOf(
              normalizeString(searchTerm)
            ) >= 0 ||
            normalizeString(node.frontmatter.author.frontmatter.name).indexOf(
              normalizeString(searchTerm)
            ) >= 0
          : true
      )
    if (filtered.length) {
      return filtered.map(
        ({
          node: {
            id,
            frontmatter: { image, title, subtitle, author, date },
            fields: { slug },
          },
        }) => (
          <BlogCard
            key={id}
            slug={slug}
            image={image}
            title={title}
            subtitle={subtitle}
            author={author.frontmatter}
            publicationDate={date}
          />
        )
      )
    } else {
      return <p>Não foram encontrados posts para esta pesquisa.</p>
    }
  }

  const categories = []
  blogPosts.edges.forEach(({ node }) => {
    if (categories.indexOf(node.frontmatter.category) < 0) {
      categories.push(node.frontmatter.category)
    }
  })

  return (
    <SimpleLayout path={props.path} isPreview={isPreview}>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        url="https://business.cloudware.pt/blog"
      />
      <header className={styles.blogHeader}>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
        </div>
        <img alt="" className={styles.blogHeaderImg} src={headerImage} />
      </header>
      <section className={styles.blog}>
        <div className={styles.content}>
          <div className={styles.search}>
            <input
              className={styles.searchInput}
              type="text"
              placeholder="Pesquisar posts"
              value={searchTerm}
              onChange={e => {
                setSearchTerm(e.target.value)
              }}
            />
            <img
              src={require("../img/search.png")}
              alt="search icon"
              className={styles.icon}
            />
          </div>
          {categories.length > 1 && (
            <div className={styles.categories}>
              {/*eslint-disable-next-line */}
              <span
                className={`${styles.category} ${
                  "" === filter ? styles.active : {}
                }`}
                onClick={() => {
                  setFilter("")
                }}
              >
                Todos
              </span>
              {categories.map(category => (
                /*eslint-disable-next-line */
                <span
                  className={`${styles.category} ${
                    category === filter ? styles.active : {}
                  }`}
                  key={category}
                  onClick={() => {
                    setFilter(category)
                  }}
                >
                  {category}
                </span>
              ))}
            </div>
          )}
          <div className={styles.blogPosts}>{posts(blogPosts)}</div>
        </div>
      </section>
      <Newsletter isPreview={isPreview} />
    </SimpleLayout>
  )
}

export default BlogPageComponent
