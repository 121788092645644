import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { StaticQuery, graphql } from "gatsby"

import { submitNewsletterForm } from "../../forms"
import DistrictList from "./components/DistricList"
import TermsAndConditions from "./components/TermsAndConditions"

import styles from "./styles/newsletter.module.css"

const NewsletterForm = ({ isPreview }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const { register, handleSubmit, errors, reset, watch } = useForm({
    defaultValues: { district: "" },
  })

  const districtValue = watch("district", "")

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
        reset()
      }, 10000)
    }
    //eslint-disable-next-line
  }, [success])

  const onSubmit = async data => {
    setLoading(true)
    try {
      await submitNewsletterForm(data)
      setSuccess(true)
    } catch (err) {
      setError(err)
    }
    setLoading(false)
  }

  if (isPreview) {
    return (
      <div style={{ padding: "100px 50px" }}>
        <h3 style={{ textAlign: "center", color: "#ccc" }}>
          Newsletter section will appear here
        </h3>
        <h5 style={{ textAlign: "center", color: "#ccc" }}>
          Preview is not available
        </h5>
      </div>
    )
  }

  const SuccessMessage = () => (
    <div
      className={styles.success}
      style={success ? { opacity: 1 } : { opacity: 0, zIndex: -1 }}
    >
      <p>O seu pedido de subscrição da newsletter foi submetido com sucesso!</p>
      <p>
        Passará a receber as novidades e comunicações da Cloudware no seu
        endereço de e-mail.
      </p>

      <p>Obrigado e até já!</p>
    </div>
  )

  const ErrorMessage = () => (
    <p className={styles.error}>
      Ocorreu um erro no envio da sua mensagem. Por favor, tente novamente mais
      tarde.
    </p>
  )

  return (
    <StaticQuery
      query={graphql`
        query {
          imageSharp(original: { src: { regex: "/.*news-letter.*/" } }) {
            fluid(maxWidth: 1200, quality: 92) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      `}
      render={data => {
        return (
          <div
            id="newsletter"
            className={styles.newsletter}
            style={{
              backgroundImage: `url(${data.imageSharp &&
                data.imageSharp.fluid.src})`,
            }}
          >
            <div className={styles.overlay} />
            <div className={styles.content}>
              <h2 className={styles.title}>
                <strong>Subscreva</strong> a cloudware news:
              </h2>

              <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <div className={styles.inline}>
                  <input
                    className={`${errors.first_name ? styles.inputError : ""} ${
                      styles.half
                    }`}
                    name="first_name"
                    placeholder="Primeiro Nome"
                    ref={register({
                      required: "Por favor insira o seu primeiro nome",
                    })}
                  />
                  <input
                    className={`${errors.last_name ? styles.inputError : ""} ${
                      styles.half
                    }`}
                    name="last_name"
                    placeholder="Último Nome"
                    ref={register({
                      required: "Por favor insira o seu último nome",
                    })}
                  />
                </div>

                <input
                  className={errors.email ? styles.inputError : ""}
                  name="email"
                  placeholder="Email"
                  ref={register({
                    required: "Por favor insira o seu email",
                  })}
                />
                <DistrictList
                  style={{
                    color: districtValue ? "#3f3f3f" : "#8e8e8e",
                  }}
                  styles={styles}
                  name="district"
                  ref={register({
                    required: "Por favor insira o seu distrito",
                  })}
                  error={errors.district}
                />
                <div className={styles.checkbox}>
                  <input
                    type="checkbox"
                    name="accepted_terms"
                    ref={register({
                      required: "Por favor aceite os termos e condições",
                    })}
                  />
                  <TermsAndConditions />
                </div>

                <div className={styles.inline}>
                  <button type="submit" className={styles.button}>
                    {loading ? "A enviar..." : "Enviar"}
                  </button>
                </div>
                {Object.keys(errors).map(error => (
                  <p className={styles.error} key={error}>
                    {errors[error].message}
                  </p>
                ))}
              </form>
            </div>
            {success && <SuccessMessage />}
            {error && <ErrorMessage />}
          </div>
        )
      }}
    />
  )
}

export default NewsletterForm
